import { Flex, List, Spacer } from "@chakra-ui/react";
import { Heading } from "@ridepanda/bamboo-ui";
import { IoCodeSlash } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";
import { UserAccessLevel } from "../../../api/types-and-hooks";
import { useApp } from "../../../contexts/AppContext";
import Colors from "../../../theme/foundations/colors";
import NavLink from "./NavLink";
import UserProfile from "./UserProfile";

export const SIDEBAR_WIDTH = "240px";

export default function Sidebar() {
  const { currentAccessLevels } = useApp();

  return (
    <Flex
      position="fixed"
      backgroundColor="#FFF"
      width={SIDEBAR_WIDTH}
      borderRight="1px solid"
      borderColor={Colors.customs.coreBorder}
      h="full"
      direction="column"
      css={{
        "@media print": {
          display: "none",
        },
      }}
      padding="12px"
      gap={1.5}
    >
      <Flex padding={2} mb={3}>
        <Heading weight="bold" size="24" variant="narrow">
          Ridepanda
        </Heading>
      </Flex>

      <List spacing={2}>
        <NavLink to="/" icon={MdOutlineDashboard} beta>
          Insights
        </NavLink>

        {currentAccessLevels.includes(UserAccessLevel.OrganizationAdmin) && (
          <NavLink to="/integrations" icon={IoCodeSlash}>
            Integrations
          </NavLink>
        )}
      </List>
      <Spacer />
      <UserProfile />
    </Flex>
  );
}
