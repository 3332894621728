import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  ColorCode: any;
  Decimal: any;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
  Url: any;
};

export type Address = {
  city?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type AnalyticsActiveRidersDetail = {
  date: Scalars['ISO8601Date'];
  ebikeCount: Scalars['Int'];
  escooterCount: Scalars['Int'];
  pedalBikeCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AnalyticsActiveRidersMetrics = {
  currentStats: AnalyticsActiveRidersStats;
  projectedStats?: Maybe<AnalyticsActiveRidersStats>;
};

export type AnalyticsActiveRidersStats = {
  carTripsAvoided: Scalars['Int'];
  details: Array<AnalyticsActiveRidersDetail>;
  totalCo2OffsetKg: Scalars['Int'];
  totalMiles: Scalars['Int'];
  treesPlantedEquivalency: Scalars['Float'];
};

export type AnalyticsActivityDetail = {
  date: Scalars['ISO8601Date'];
  ordersCount: Scalars['Int'];
  sessionsCount: Scalars['Int'];
  uniqueVisitorsCount: Scalars['Int'];
};

export type AnalyticsActivityMetrics = {
  currentStats: AnalyticsActivityStats;
  previousStats: AnalyticsActivityStats;
};

export type AnalyticsActivityStats = {
  date: Scalars['ISO8601Date'];
  details: Array<AnalyticsActivityDetail>;
  ordersCount: Scalars['Int'];
  sessionsCount: Scalars['Int'];
  uniqueVisitorsCount: Scalars['Int'];
};

export type Asset = {
  allowBuyout: Scalars['Boolean'];
  assetSubscriptions: Array<AssetSubscription>;
  buyoutAvailableAt?: Maybe<Scalars['ISO8601Date']>;
  buyoutEligible: Scalars['Boolean'];
  buyoutPriceCents?: Maybe<Scalars['Int']>;
  checkedInAt?: Maybe<Scalars['ISO8601DateTime']>;
  cogsCents?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<Note>>;
  createdAt: Scalars['ISO8601DateTime'];
  customerInvoices?: Maybe<Array<CustomerInvoice>>;
  events?: Maybe<Array<Event>>;
  frameKey?: Maybe<Scalars['String']>;
  hub: Hub;
  id: Scalars['ID'];
  lifetimeEarningCents: Scalars['Int'];
  lockKey?: Maybe<Scalars['String']>;
  msrpAtPurchaseCents: Scalars['Int'];
  nextStates?: Maybe<Array<AssetState>>;
  order?: Maybe<Order>;
  orders?: Maybe<Array<Order>>;
  pledged: Scalars['Boolean'];
  pledgedAt?: Maybe<Scalars['ISO8601DateTime']>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  purchasedAt?: Maybe<Scalars['ISO8601DateTime']>;
  sale?: Maybe<AssetSale>;
  serialNumber?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['ISO8601DateTime']>;
  soldAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: AssetState;
  subscription?: Maybe<Subscription>;
  subscriptionRevenueCents: Scalars['Int'];
  subscriptions?: Maybe<Array<Subscription>>;
  theftReport?: Maybe<TheftReport>;
  totalRevenueCents: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  variant: Variant;
  wholesalePriceCents: Scalars['Int'];
};

export type AssetCheckInInput = {
  /** If true, the linked order will be fulfilled */
  fulfillLinkedOrder?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the asset to check in */
  id: Scalars['ID'];
  /** The serial number of the asset */
  serialNumber: Scalars['String'];
};

/** The connection type for Asset. */
export type AssetConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AssetEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Asset>>>;
  pageInfo: PageInfo;
};

export type AssetDetailsInput = {
  /** Whether the asset is buyout eligible */
  buyoutEligible?: InputMaybe<Scalars['Boolean']>;
  /** The frame key of the asset */
  frameKey?: InputMaybe<Scalars['String']>;
  /** The lock key of the asset */
  lockKey?: InputMaybe<Scalars['String']>;
  /** The serial number of the asset */
  serialNumber: Scalars['String'];
};

/** An edge in a connection. */
export type AssetEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Asset>;
};

export type AssetInput = {
  /** The hub ID to associate the asset with.If nil, the hub will be inferred from the purchase order */
  hubId?: InputMaybe<Scalars['ID']>;
  /** The ID of the asset to update */
  id?: InputMaybe<Scalars['ID']>;
  /** The manufacturer's suggested retail price of the asset, in cents */
  msrpAtPurchaseCents?: InputMaybe<Scalars['Int']>;
  /** The purchase order ID to associate the asset with. */
  purchaseOrderId?: InputMaybe<Scalars['ID']>;
  /** The serial number of the asset */
  serialNumber?: InputMaybe<Scalars['String']>;
  /** The variant ID to associate the asset with */
  variantId: Scalars['ID'];
  /** The wholesale price of the asset, in cents */
  wholesalePriceCents: Scalars['Int'];
};

export enum AssetMaintenanceState {
  /** maintenance_cosmetic asset state */
  MaintenanceCosmetic = 'MAINTENANCE_COSMETIC',
  /** maintenance_hold asset state */
  MaintenanceHold = 'MAINTENANCE_HOLD',
  /** maintenance_long_term asset state */
  MaintenanceLongTerm = 'MAINTENANCE_LONG_TERM',
  /** maintenance_ready_for_pickup asset state */
  MaintenanceReadyForPickup = 'MAINTENANCE_READY_FOR_PICKUP',
  /** maintenance_rma asset state */
  MaintenanceRma = 'MAINTENANCE_RMA'
}

export type AssetSale = {
  asset: Asset;
  buyoutPriceCents: Scalars['Int'];
  channel: SalesChannel;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  soldAt: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum AssetSortColumn {
  customer = 'customer',
  SerialNumber = 'serialNumber',
  StateWeight = 'stateWeight',
  vehicle = 'vehicle'
}

export enum AssetState {
  /** active asset state */
  Active = 'ACTIVE',
  /** decommissioned asset state */
  Decommissioned = 'DECOMMISSIONED',
  /** fleet_rental asset state */
  FleetRental = 'FLEET_RENTAL',
  /** maintenance_cosmetic asset state */
  MaintenanceCosmetic = 'MAINTENANCE_COSMETIC',
  /** maintenance_hold asset state */
  MaintenanceHold = 'MAINTENANCE_HOLD',
  /** maintenance_long_term asset state */
  MaintenanceLongTerm = 'MAINTENANCE_LONG_TERM',
  /** maintenance_ready_for_pickup asset state */
  MaintenanceReadyForPickup = 'MAINTENANCE_READY_FOR_PICKUP',
  /** maintenance_rma asset state */
  MaintenanceRma = 'MAINTENANCE_RMA',
  /** open_po asset state */
  OpenPo = 'OPEN_PO',
  /** received asset state */
  Received = 'RECEIVED',
  /** returned asset state */
  Returned = 'RETURNED',
  /** shipped asset state */
  Shipped = 'SHIPPED',
  /** sold asset state */
  Sold = 'SOLD',
  /** stolen asset state */
  Stolen = 'STOLEN',
  /** test_ride asset state */
  TestRide = 'TEST_RIDE'
}

export type AssetSubscription = {
  asset: Asset;
  endedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  startedAt: Scalars['ISO8601DateTime'];
  subscription: Subscription;
};

export type AssetsByVariant = {
  assets: Array<Asset>;
  color: Scalars['String'];
  size: Scalars['String'];
};

export type AssetsForPoTab = {
  assetsByVariant: Array<AssetsByVariant>;
  brand: Scalars['String'];
  model: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type BatchOperationResult = {
  failed: Array<ResourceError>;
  succeeded: Array<Scalars['ID']>;
};

export type Card = {
  brand: Scalars['String'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  last4: Scalars['String'];
};

export type CardUpdateSessionCreatePayload = {
  clientSecret: Scalars['String'];
};

export type CheckoutDataUpdateInput = {
  billingAddress?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']>;
  helmetSize?: InputMaybe<HelmetSize>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInput>;
};

export type CheckoutSessionCreatePayload = {
  buyoutPriceCents: Scalars['Int'];
  clientSecret: Scalars['String'];
  clientSecretType: Scalars['String'];
  discount?: Maybe<LineItem>;
  /** @deprecated not used */
  subtotal: LineItem;
  taxAmount?: Maybe<LineItem>;
  /** @deprecated use `tax_amount` */
  taxRate?: Maybe<LineItem>;
  termLength: Scalars['Int'];
  total: LineItem;
};

export enum CurrencyCode {
  /** United States Dollars (USD). */
  Usd = 'USD'
}

export type Customer = {
  activationCode: Scalars['String'];
  /** @deprecated Use `shipping_address` instead */
  address?: Maybe<Address>;
  addresses?: Maybe<Array<Address>>;
  billingAddress?: Maybe<Address>;
  card?: Maybe<Card>;
  currentSubscription?: Maybe<Subscription>;
  email?: Maybe<Scalars['String']>;
  events: Array<Event>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  frontContactId?: Maybe<Scalars['String']>;
  helmetSize?: Maybe<HelmetSize>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  lastOrder?: Maybe<Order>;
  latestPromotion?: Maybe<Promotion>;
  leaseToOwn?: Maybe<LeaseToOwn>;
  lightspeedCustomerId?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  office?: Maybe<Office>;
  organization: Organization;
  phone?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Address>;
  /** @deprecated use `leaseToOwn` instead */
  spoke?: Maybe<LeaseToOwn>;
  stripeCustomerId: Scalars['String'];
};

/** The connection type for Customer. */
export type CustomerConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Customer>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Customer>;
};

export type CustomerInvoice = {
  address?: Maybe<Address>;
  asset?: Maybe<Asset>;
  createdAt: Scalars['ISO8601DateTime'];
  currencyCode: Scalars['String'];
  customer: Customer;
  id: Scalars['ID'];
  number: Scalars['String'];
  organization: Organization;
  paidAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: CustomerInvoiceState;
  stripeInvoiceId: Scalars['String'];
  stripePriceId: Scalars['String'];
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  swapped: Scalars['Boolean'];
  totalCents?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum CustomerInvoiceKind {
  /** buyout customer invoice kind */
  Buyout = 'BUYOUT',
  /** month_to_month customer invoice kind */
  MonthToMonth = 'MONTH_TO_MONTH',
  /** restock_fee customer invoice kind */
  RestockFee = 'RESTOCK_FEE'
}

export enum CustomerInvoiceState {
  /** canceled customer invoice state */
  Canceled = 'CANCELED',
  /** paid customer invoice state */
  Paid = 'PAID',
  /** pending customer invoice state */
  Pending = 'PENDING',
  /** refunded customer invoice state */
  Refunded = 'REFUNDED'
}

export enum CustomerSortColumn {
  CreatedAt = 'createdAt',
  email = 'email',
  FirstName = 'firstName',
  HubName = 'hubName',
  LastName = 'lastName',
  LeaseToOwnName = 'leaseToOwnName',
  OfficeName = 'officeName',
  OrganizationName = 'organizationName'
}

export type CustomerUpdateInput = {
  /** Billing address of the customer */
  billingAddress?: InputMaybe<AddressInput>;
  /** Email of the customer */
  email?: InputMaybe<Scalars['String']>;
  /** First name of the customer */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last name of the customer */
  lastName?: InputMaybe<Scalars['String']>;
  /** Phone of the customer */
  phone?: InputMaybe<Scalars['String']>;
  /** Shipping address of the customer */
  shippingAddress?: InputMaybe<AddressInput>;
};

export type Event = {
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ExportReportStatus {
  /** done export state */
  Done = 'DONE',
  /** failed export state */
  Failed = 'FAILED',
  /** in_progress export state */
  InProgress = 'IN_PROGRESS',
  /** queued export state */
  Queued = 'QUEUED'
}

export type Faq = {
  answer: Scalars['String'];
  id: Scalars['ID'];
  question: Scalars['String'];
};

export type FaqInput = {
  /** Answer used in body */
  answer: Scalars['String'];
  /** FAQ ID */
  id?: InputMaybe<Scalars['ID']>;
  /** Question used in title */
  question: Scalars['String'];
};

export type FileUploadUrlPayload = {
  /** Indicates whether this file was already uploaded */
  existingFile: Scalars['Boolean'];
  /** The HTTP method to use to upload */
  httpMethod: Scalars['String'];
  /** The URL to view the file when uploaded */
  publicUrl: Scalars['Url'];
  /** The URL to use to upload */
  uploadUrl: Scalars['Url'];
};

/** A float range */
export type FloatRange = {
  isSingleValue: Scalars['Boolean'];
  max?: Maybe<Scalars['Float']>;
  min: Scalars['Float'];
};

/** A float range */
export type FloatRangeInput = {
  isSingleValue: Scalars['Boolean'];
  max?: InputMaybe<Scalars['Float']>;
  min: Scalars['Float'];
};

export enum HelmetSize {
  /** extra_large customer helmet size */
  ExtraLarge = 'EXTRA_LARGE',
  /** large customer helmet size */
  Large = 'LARGE',
  /** small_medium customer helmet size */
  SmallMedium = 'SMALL_MEDIUM'
}

export type Hub = {
  address?: Maybe<Address>;
  /** @deprecated Use address instead */
  addressObj?: Maybe<Address>;
  businessHours?: Maybe<Scalars['String']>;
  deliveryUrl?: Maybe<Scalars['String']>;
  directShipEnabled: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  googleMapsUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locations: Array<Location>;
  locationsPageCopy?: Maybe<Scalars['String']>;
  maxTimeToFulfill: Scalars['Int'];
  minTimeToFulfill: Scalars['Int'];
  name: Scalars['String'];
  pandacareUrl?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  readyForPickupUrl?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<ServiceArea>;
  slug?: Maybe<Scalars['String']>;
  stripeTaxRateId?: Maybe<Scalars['String']>;
  taxRatePercentage?: Maybe<Scalars['Float']>;
  testRideUrl?: Maybe<Scalars['String']>;
};

export type HubCheckoutData = {
  billingAddress?: Maybe<Address>;
  email: Scalars['String'];
  expiredAt: Scalars['ISO8601DateTime'];
  firstName?: Maybe<Scalars['String']>;
  helmetSize?: Maybe<HelmetSize>;
  hub: Hub;
  lastName?: Maybe<Scalars['String']>;
  lineItems: HubCheckoutLineItems;
  office?: Maybe<Office>;
  organization: Organization;
  phone?: Maybe<Scalars['String']>;
  plan: Plan;
  promotion?: Maybe<Promotion>;
  shippingAddress?: Maybe<Address>;
  token: Scalars['String'];
  variant: Variant;
};

/** Autogenerated return type of HubCheckoutDataCreate. */
export type HubCheckoutDataCreatePayload = {
  token: Scalars['String'];
};

export type HubCheckoutLineItems = {
  subsidy: LineItem;
  subtotal: LineItem;
  tax: LineItem;
  total: LineItem;
  vehicle: LineItem;
};

export type HubInput = {
  address?: InputMaybe<AddressInput>;
  /** The business hours of the hub */
  businessHours?: InputMaybe<Scalars['String']>;
  /** The Calendly URL for when the vehicle is ready for pickup at the hub */
  deliveryUrl?: InputMaybe<Scalars['Url']>;
  /** Whether direct ship is enabled for the hub */
  directShipEnabled: Scalars['Boolean'];
  /** The contact email of the hub */
  email?: InputMaybe<Scalars['String']>;
  /** The Google Maps URL for the hub */
  googleMapsUrl?: InputMaybe<Scalars['Url']>;
  /** The description for the hub in Portal's locations page */
  locationsPageCopy?: InputMaybe<Scalars['String']>;
  /** Maximum time to fulfill an order */
  maxTimeToFulfill?: Scalars['Int'];
  /** Minimum time to fulfill an order */
  minTimeToFulfill?: Scalars['Int'];
  /** The name of the hub */
  name: Scalars['String'];
  /** The PandaCare diagnostics URL for the hub */
  pandacareUrl?: InputMaybe<Scalars['Url']>;
  /** The contact phone of the hub */
  phone?: InputMaybe<Scalars['String']>;
  /** The Ready for Pickup Calendly URL for the hub */
  readyForPickupUrl?: InputMaybe<Scalars['Url']>;
  /** The service area ID for the hub */
  serviceAreaId?: InputMaybe<Scalars['ID']>;
  /** The Stripe Tax Rate ID for the hub */
  stripeTaxRateId: Scalars['String'];
  /** The Test Ride URL for the hub */
  testRideUrl?: InputMaybe<Scalars['Url']>;
};

export type HubVariant = {
  allowBackorders: Scalars['Boolean'];
  hub: Hub;
  id: Scalars['ID'];
  variant: Variant;
  variantCount: Scalars['Int'];
};

export type HubVariantInput = {
  /** Allow Backorders */
  allowBackorders: Scalars['Boolean'];
  /** Hub ID */
  hubId: Scalars['ID'];
  /** Variant count */
  variantCount: Scalars['Int'];
  /** Variant ID */
  variantId: Scalars['ID'];
};

export type HubVehicleCategoriesInput = {
  /** Hub ID */
  hubId: Scalars['ID'];
  /** List of Vehicle Categories of the Hub */
  vehicleCategories: Array<VehicleCategory>;
};

export type Image = {
  id: Scalars['ID'];
  url: Scalars['Url'];
};

export type Invoice = {
  /** @deprecated Use `amount_paid_cents` instead */
  amountPaid: Money;
  amountPaidCents: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  invoicePdfUrl: Scalars['Url'];
  kind: CustomerInvoiceKind;
  number: Scalars['String'];
  /** @deprecated Use `invoice_pdf_url` instead */
  pdfUrl: Scalars['Url'];
  receiptPdfUrl?: Maybe<Scalars['Url']>;
  state: CustomerInvoiceState;
  /** @deprecated Use `state` instead */
  status: CustomerInvoiceState;
  stripePaymentUrl?: Maybe<Scalars['Url']>;
  subsidyAmountCents: Scalars['Int'];
  totalRefundedCents: Scalars['Int'];
};

/** Return type for `joinWaitlist` mutation */
export type JoinWaitlistPayload = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
};

export type LeaseToOwn = {
  active: Scalars['Boolean'];
  address?: Maybe<Address>;
  admins: Array<User>;
  businessHours: Scalars['String'];
  discountPercentage: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  googleMapsUrl: Scalars['Url'];
  id: Scalars['ID'];
  locations: Array<Location>;
  logo?: Maybe<Image>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  salesTaxEnabled: Scalars['Boolean'];
  salesTaxPercentage: Scalars['Float'];
  serviceArea?: Maybe<ServiceArea>;
  stripeConnectedAccountId?: Maybe<Scalars['String']>;
  websiteUrl: Scalars['Url'];
};

export type LeaseToOwnInvoice = {
  accessoriesRetailPriceCents: Scalars['Int'];
  canceledReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  discountCents: Scalars['Int'];
  discountPercentage: Scalars['Float'];
  /** The date after which the associated lease term length ends, or the date the lease was canceled */
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  /** @deprecated use `media_attachment` instead */
  invoiceDocUrl?: Maybe<Scalars['Url']>;
  kind: LeaseToOwnInvoiceKind;
  lease?: Maybe<LeaseToOwnLease>;
  leaseToOwn: LeaseToOwn;
  mediaAttachment?: Maybe<MediaAttachment>;
  notes: Array<Note>;
  salesOrderNumber?: Maybe<Scalars['String']>;
  salesTaxCents: Scalars['Int'];
  salesTaxPercentage: Scalars['Float'];
  /** @deprecated use `leaseToOwn` instead */
  spoke: LeaseToOwn;
  state: LeaseToOwnInvoiceState;
  stateUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  totalCents: Scalars['Int'];
  vehicleBrand: Scalars['String'];
  vehicleCategory: VehicleCategory;
  vehicleColor: Scalars['String'];
  vehicleDescription: Scalars['String'];
  vehicleDisplayName: Scalars['String'];
  vehicleModel: Scalars['String'];
  vehicleRetailPriceCents: Scalars['Int'];
  vehicleSerialNumber: Scalars['String'];
  vehicleSize: Scalars['String'];
};

/** The connection type for LeaseToOwnInvoice. */
export type LeaseToOwnInvoiceConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LeaseToOwnInvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LeaseToOwnInvoice>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LeaseToOwnInvoiceEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LeaseToOwnInvoice>;
};

export enum LeaseToOwnInvoiceKind {
  /** lease invoice_kind */
  Lease = 'LEASE',
  /** maintenance invoice_kind */
  Maintenance = 'MAINTENANCE'
}

export enum LeaseToOwnInvoiceState {
  /** approved invoice_state */
  Approved = 'APPROVED',
  /** needs_review invoice_state */
  NeedsReview = 'NEEDS_REVIEW',
  /** paid invoice_state */
  Paid = 'PAID',
  /** pending invoice_state */
  Pending = 'PENDING'
}

export enum LeaseToOwnInvoicesSortColumn {
  CreatedAt = 'createdAt',
  CustomerName = 'customerName',
  kind = 'kind',
  LeaseToOwnName = 'leaseToOwnName',
  OrganizationName = 'organizationName',
  SalesOrderNumber = 'salesOrderNumber',
  state = 'state',
  TotalCents = 'totalCents'
}

export type LeaseToOwnLease = {
  buyoutDate?: Maybe<Scalars['ISO8601Date']>;
  buyoutPriceCents?: Maybe<Scalars['Int']>;
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentPeriodEnd?: Maybe<Scalars['ISO8601Date']>;
  currentPeriodStart?: Maybe<Scalars['ISO8601Date']>;
  customer: Customer;
  id: Scalars['ID'];
  monthlyTotalPriceCents: Scalars['Int'];
  organization: Organization;
  paymentDetails: PaymentDetails;
  state: LeaseToOwnLeaseState;
  stripeSubscriptionId: Scalars['String'];
  termLengthMonths: Scalars['Int'];
  vehicle: LeaseToOwnVehicle;
};

export enum LeaseToOwnLeaseState {
  /** active lease_to_own_lease_state */
  Active = 'ACTIVE',
  /** canceled lease_to_own_lease_state */
  Canceled = 'CANCELED'
}

export type LeaseToOwnVehicle = {
  brand: Scalars['String'];
  color: Scalars['String'];
  description: Scalars['String'];
  displayName: Scalars['String'];
  model: Scalars['String'];
  serialNumber: Scalars['String'];
  size: Scalars['String'];
};

export type LineItem = {
  /** Decimal money amount. */
  amount: Scalars['Decimal'];
  /** Currency of the money. */
  currencyCode: CurrencyCode;
  /** The receipt display name */
  displayName: Scalars['String'];
};

export type Location = {
  editable: Scalars['Boolean'];
  hub?: Maybe<Hub>;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export type LogHistory = {
  category: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  createdBy: Scalars['String'];
  modifications: Scalars['JSON'];
};

export type Media = {
  id: Scalars['ID'];
  url: Scalars['Url'];
};

export type MediaAttachment = {
  media: Media;
  uploadedBy?: Maybe<Scalars['String']>;
};

export type MediaInput = {
  /** Media id. Media must exist if id is set */
  id?: InputMaybe<Scalars['ID']>;
  /** Media url. Creates a file if none found with given url */
  url?: InputMaybe<Scalars['Url']>;
};

export type Money = {
  /** Decimal money amount. */
  amount: Scalars['Decimal'];
  /** Currency of the money. */
  currencyCode: CurrencyCode;
};

export type MoneyInput = {
  /** Decimal money amount. */
  amount: Scalars['Decimal'];
  /** Currency of the money. */
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type Mutation = {
  /** Updates the asset state to `active`. */
  assetActivate?: Maybe<Asset>;
  /** Updates the asset state to `received`. Updates PO to `check in` for first assetand `closed` if all assets are received */
  assetCheckIn?: Maybe<Asset>;
  /** Updates the asset state to `decommissioned` */
  assetDecommission?: Maybe<Asset>;
  /** Puts the asset in a maintenance state */
  assetMaintenance?: Maybe<Asset>;
  /** Updates the asset state to `maintenance_hold` */
  assetMaintenanceHold?: Maybe<Asset>;
  /** Updates the asset state to `sold` and creates an asset sale */
  assetReportSold?: Maybe<Asset>;
  /** Updates the asset state to `stolen`.It also updates the subscription state to `canceled` if the asset is currently leased */
  assetReportStolen?: Maybe<Asset>;
  /** Updates the asset state to `returned`. */
  assetReturn?: Maybe<Asset>;
  /** Updates the asset state to `test_ride`. */
  assetTestRide?: Maybe<Asset>;
  /** Updates the asset attributes */
  assetUpdate?: Maybe<Asset>;
  /** Updates the asset's hub */
  assetUpdateHub?: Maybe<Asset>;
  /** Creates a job to export assets */
  assetsExport?: Maybe<ReportExport>;
  assetsMarkCheckedIn?: Maybe<BatchOperationResult>;
  assetsMarkShipped?: Maybe<BatchOperationResult>;
  cardUpdateSessionCreate?: Maybe<CardUpdateSessionCreatePayload>;
  checkoutDataCreate?: Maybe<HubCheckoutDataCreatePayload>;
  checkoutDataUpdate?: Maybe<TransactionResult>;
  checkoutSessionCreate?: Maybe<CheckoutSessionCreatePayload>;
  checkoutSessionCreateV2?: Maybe<CheckoutSessionCreatePayload>;
  customerUpdate?: Maybe<Customer>;
  fileUploadUrlCreate?: Maybe<FileUploadUrlPayload>;
  forestUserUpdate?: Maybe<User>;
  hubCreateOrUpdate?: Maybe<Hub>;
  joinWaitlist?: Maybe<JoinWaitlistPayload>;
  noteCreate?: Maybe<Note>;
  officeCreate?: Maybe<Office>;
  officeUpdate?: Maybe<Office>;
  orderCancel?: Maybe<Order>;
  orderCreate?: Maybe<OrderValidationResult>;
  orderFulfill?: Maybe<Order>;
  orderOnHold?: Maybe<Order>;
  orderOpen?: Maybe<Order>;
  orderReady?: Maybe<Order>;
  orderRemoveAsset?: Maybe<Order>;
  orderVariantUpdate?: Maybe<Order>;
  /** Creates a job to export orders */
  ordersExport?: Maybe<ReportExport>;
  organizationCreateOrUpdate?: Maybe<Organization>;
  /** Creates a job to export paid invoices from stripe */
  paidInvoicesExport?: Maybe<ReportExport>;
  promotionActivate?: Maybe<Promotion>;
  promotionCreateOrUpdate?: Maybe<Promotion>;
  /** Creates a job to export orders linked to a specific promotion */
  promotionOrdersExport?: Maybe<ReportExport>;
  promotionPause?: Maybe<Promotion>;
  purchaseOrderAndOrderUnlink?: Maybe<TransactionResult>;
  /** Creates a job to export purchase order details */
  purchaseOrderAssetsExport?: Maybe<ReportExport>;
  /** Creates a Purchase Order for a direct ship Order */
  purchaseOrderCreateForOrder?: Maybe<PurchaseOrder>;
  purchaseOrderCreateOrUpdate?: Maybe<PurchaseOrder>;
  purchaseOrderDelete?: Maybe<TransactionResult>;
  purchaseOrderShip?: Maybe<PurchaseOrder>;
  purchaseOrderSubmit?: Maybe<PurchaseOrder>;
  /** Creates a job to export revenue by state */
  revenueByStateExport?: Maybe<ReportExport>;
  serviceAreaCreateOrUpdate?: Maybe<ServiceArea>;
  subscriptionBuyout?: Maybe<Subscription>;
  subscriptionCancel?: Maybe<Subscription>;
  subscriptionCustomerOffboard?: Maybe<Subscription>;
  subscriptionUpdate?: Maybe<Subscription>;
  subscriptionVariantUpdate?: Maybe<Variant>;
  /** Creates a job to export subscriptions */
  subscriptionsExport?: Maybe<ReportExport>;
  theftReportCreateOrUpdate?: Maybe<TheftReport>;
  variantHubInventoryUpdate?: Maybe<Array<HubVariant>>;
  /** Creates a job to export Variant Stats */
  variantStatsExport?: Maybe<ReportExport>;
  vehicleCreateOrUpdate?: Maybe<Vehicle>;
  /** Passing empty `id` will create a new vendor, otherwise it will update it */
  vendorCreateOrUpdate?: Maybe<Vendor>;
};


export type MutationAssetActivateArgs = {
  assetId: Scalars['ID'];
  maintenanceNotes?: InputMaybe<Scalars['String']>;
};


export type MutationAssetCheckInArgs = {
  id: Scalars['ID'];
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationAssetDecommissionArgs = {
  assetId: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationAssetMaintenanceArgs = {
  assetId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<AssetMaintenanceState>;
};


export type MutationAssetMaintenanceHoldArgs = {
  assetId: Scalars['ID'];
  maintenanceDescription?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationAssetReportSoldArgs = {
  assetId: Scalars['ID'];
  buyoutPriceCents: Scalars['Int'];
  customerEmail?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  referenceNumber?: InputMaybe<Scalars['String']>;
  salesChannel: SalesChannel;
  soldAt: Scalars['String'];
};


export type MutationAssetReportStolenArgs = {
  assetId: Scalars['ID'];
};


export type MutationAssetReturnArgs = {
  assetId: Scalars['ID'];
  maintenanceNotes?: InputMaybe<Scalars['String']>;
};


export type MutationAssetTestRideArgs = {
  assetId: Scalars['ID'];
};


export type MutationAssetUpdateArgs = {
  assetId: Scalars['ID'];
  attributes: AssetDetailsInput;
};


export type MutationAssetUpdateHubArgs = {
  assetId: Scalars['ID'];
  hubId: Scalars['ID'];
};


export type MutationAssetsExportArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  includeActiveSubscription?: InputMaybe<Scalars['Boolean']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<AssetSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type MutationAssetsMarkCheckedInArgs = {
  assets: Array<AssetCheckInInput>;
};


export type MutationAssetsMarkShippedArgs = {
  ids: Array<Scalars['ID']>;
  timestamp?: InputMaybe<Scalars['ISO8601DateTime']>;
};


export type MutationCheckoutDataCreateArgs = {
  email: Scalars['String'];
  officeId: Scalars['ID'];
  organizationId: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type MutationCheckoutDataUpdateArgs = {
  attributes: CheckoutDataUpdateInput;
  token: Scalars['String'];
};


export type MutationCheckoutSessionCreateArgs = {
  email: Scalars['String'];
  locationId?: InputMaybe<Scalars['ID']>;
  officeId?: InputMaybe<Scalars['ID']>;
  organizationSlug: Scalars['String'];
  variantId: Scalars['ID'];
};


export type MutationCheckoutSessionCreateV2Args = {
  token: Scalars['String'];
};


export type MutationCustomerUpdateArgs = {
  attributes: CustomerUpdateInput;
  customerId: Scalars['ID'];
};


export type MutationFileUploadUrlCreateArgs = {
  fileSize: Scalars['BigInt'];
  mimeType: Scalars['String'];
  prefix: Scalars['String'];
};


export type MutationForestUserUpdateArgs = {
  disabled?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  hubId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationHubCreateOrUpdateArgs = {
  attributes: HubInput;
  hubId?: InputMaybe<Scalars['ID']>;
};


export type MutationJoinWaitlistArgs = {
  email: Scalars['String'];
  locationId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


export type MutationNoteCreateArgs = {
  notableId: Scalars['ID'];
  notableType: Scalars['String'];
  text: Scalars['String'];
};


export type MutationOfficeCreateArgs = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  serviceAreaId: Scalars['ID'];
};


export type MutationOfficeUpdateArgs = {
  attributes: OfficeUpdateInput;
  id: Scalars['ID'];
};


export type MutationOrderCancelArgs = {
  cancelationNotes?: InputMaybe<Scalars['String']>;
  cancelationReason: OrderCancelationReason;
  id: Scalars['ID'];
  refund?: InputMaybe<Scalars['Boolean']>;
};


export type MutationOrderCreateArgs = {
  customerEmail?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<OrderKind>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
  swapNotes?: InputMaybe<Scalars['String']>;
  swapReason?: InputMaybe<OrderSwapReason>;
  variantId: Scalars['ID'];
};


export type MutationOrderFulfillArgs = {
  chargeRestockFee?: InputMaybe<Scalars['Boolean']>;
  fulfillmentKind?: InputMaybe<OrderFulfillmentKind>;
  id: Scalars['ID'];
  maintenanceNotes?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationOrderOnHoldArgs = {
  id: Scalars['ID'];
};


export type MutationOrderOpenArgs = {
  id: Scalars['ID'];
};


export type MutationOrderReadyArgs = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  vehicleSerialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationOrderRemoveAssetArgs = {
  id: Scalars['ID'];
};


export type MutationOrderVariantUpdateArgs = {
  id: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type MutationOrdersExportArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<OrderSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type MutationOrganizationCreateOrUpdateArgs = {
  attributes: OrganizationInput;
  organizationId?: InputMaybe<Scalars['ID']>;
};


export type MutationPaidInvoicesExportArgs = {
  endDate: Scalars['ISO8601Date'];
  hubId?: InputMaybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};


export type MutationPromotionActivateArgs = {
  id: Scalars['ID'];
};


export type MutationPromotionCreateOrUpdateArgs = {
  attributes: PromotionInput;
  promotionId?: InputMaybe<Scalars['ID']>;
};


export type MutationPromotionOrdersExportArgs = {
  promotionId: Scalars['ID'];
};


export type MutationPromotionPauseArgs = {
  id: Scalars['ID'];
};


export type MutationPurchaseOrderAndOrderUnlinkArgs = {
  orderId: Scalars['ID'];
  purchaseOrderId: Scalars['ID'];
};


export type MutationPurchaseOrderAssetsExportArgs = {
  id: Scalars['ID'];
};


export type MutationPurchaseOrderCreateForOrderArgs = {
  orderId: Scalars['ID'];
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type MutationPurchaseOrderCreateOrUpdateArgs = {
  attributes: PurchaseOrderInput;
  purchaseOrderId?: InputMaybe<Scalars['ID']>;
};


export type MutationPurchaseOrderDeleteArgs = {
  purchaseOrderId: Scalars['ID'];
};


export type MutationPurchaseOrderShipArgs = {
  purchaseOrderId: Scalars['ID'];
};


export type MutationPurchaseOrderSubmitArgs = {
  invoiceDocUrl?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  purchaseOrderId: Scalars['ID'];
  vendorSalesOrderId: Scalars['String'];
};


export type MutationRevenueByStateExportArgs = {
  endDate: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
};


export type MutationServiceAreaCreateOrUpdateArgs = {
  attributes: ServiceAreaInput;
  serviceAreaId: Scalars['ID'];
};


export type MutationSubscriptionBuyoutArgs = {
  buyoutPriceCents: Scalars['Int'];
  soldAt?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
};


export type MutationSubscriptionCancelArgs = {
  attributes: SubscriptionCancelInput;
  subscriptionId: Scalars['ID'];
};


export type MutationSubscriptionCustomerOffboardArgs = {
  email: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


export type MutationSubscriptionUpdateArgs = {
  attributes: SubscriptionUpdateInput;
  subscriptionId: Scalars['ID'];
};


export type MutationSubscriptionVariantUpdateArgs = {
  subscriptionId: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type MutationSubscriptionsExportArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<SubscriptionSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type MutationTheftReportCreateOrUpdateArgs = {
  attributes: TheftReportInput;
  theftReportId?: InputMaybe<Scalars['ID']>;
};


export type MutationVariantHubInventoryUpdateArgs = {
  variantsInput: Array<HubVariantInput>;
};


export type MutationVariantStatsExportArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  onlyNeeded?: InputMaybe<Scalars['Boolean']>;
  vendorIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationVehicleCreateOrUpdateArgs = {
  attributes: VehicleUpdateInput;
  vehicleId?: InputMaybe<Scalars['ID']>;
};


export type MutationVendorCreateOrUpdateArgs = {
  attributes: VendorInput;
  id?: InputMaybe<Scalars['ID']>;
};

export type Note = {
  createdAt: Scalars['ISO8601DateTime'];
  createdByEmail: Scalars['String'];
  id: Scalars['ID'];
  notableId: Scalars['ID'];
  notableType: Scalars['String'];
  text: Scalars['String'];
};

export type Office = {
  addresses: Array<Address>;
  comparisonTabVisible: Scalars['Boolean'];
  currentAddress?: Maybe<Address>;
  directShipEnabled: Scalars['Boolean'];
  discoverable: Scalars['Boolean'];
  id: Scalars['ID'];
  leaseToOwnTabVisible: Scalars['Boolean'];
  leaseToOwns: Array<LeaseToOwn>;
  name: Scalars['String'];
  organization: Organization;
  permittedVehicleTypes: Array<VehicleCategory>;
  programsAvailable: Array<RidepandaProgramKind>;
  serviceArea: ServiceArea;
  slug: Scalars['String'];
  spokeEnabled: Scalars['Boolean'];
  /** @deprecated use `leaseToOwns` instead */
  spokes: Array<LeaseToOwn>;
  subscriptionTabVisible: Scalars['Boolean'];
  vehicleExceptions: Array<Vehicle>;
};

/** The connection type for Office. */
export type OfficeConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OfficeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Office>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OfficeEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Office>;
};

export type OfficeQueryInput = {
  id?: InputMaybe<Scalars['ID']>;
  slugs?: InputMaybe<OfficeSlugsQueryInput>;
};

export type OfficeSlugsQueryInput = {
  /** The office slug */
  office: Scalars['String'];
  /** The organization slug */
  organization: Scalars['String'];
};

/** The input fields used to update an Office record */
export type OfficeUpdateInput = {
  address?: InputMaybe<AddressInput>;
  directShipEnabled?: InputMaybe<Scalars['Boolean']>;
  discoverable?: InputMaybe<Scalars['Boolean']>;
  includedVehicleIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  permittedVehicleTypes?: InputMaybe<Array<VehicleCategory>>;
  serviceAreaId?: InputMaybe<Scalars['ID']>;
  spokeEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type Order = {
  asset?: Maybe<Asset>;
  cancelationNotes?: Maybe<Scalars['String']>;
  cancelationReason?: Maybe<OrderCancelationReason>;
  closedAt?: Maybe<Scalars['ISO8601DateTime']>;
  comments: Array<Note>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  directShip: Scalars['Boolean'];
  events: Array<Event>;
  fulfillmentKind?: Maybe<OrderFulfillmentKind>;
  hub: Hub;
  id: Scalars['ID'];
  kind: OrderKind;
  lease?: Maybe<Subscription>;
  organization: Organization;
  paymentDetails: PaymentDetails;
  plan: Plan;
  promotion?: Maybe<Promotion>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  readiedAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: OrderState;
  stateUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  stripeCheckoutInvoiceId?: Maybe<Scalars['String']>;
  subscription?: Maybe<Subscription>;
  swapNotes?: Maybe<Scalars['String']>;
  swapReason?: Maybe<OrderSwapReason>;
  updatedAt: Scalars['ISO8601DateTime'];
  variant: Variant;
  vehicleSerialNumber?: Maybe<Scalars['String']>;
};

export enum OrderCancelationReason {
  /** ordered_by_mistake order cancelation reason */
  OrderedByMistake = 'ORDERED_BY_MISTAKE',
  /** other order cancelation reason */
  Other = 'OTHER',
  /** purchased_elsewhere order cancelation reason */
  PurchasedElsewhere = 'PURCHASED_ELSEWHERE',
  /** taking_too_long order cancelation reason */
  TakingTooLong = 'TAKING_TOO_LONG',
  /** unknown order cancelation reason */
  Unknown = 'UNKNOWN',
  /** variant_change order cancelation reason */
  VariantChange = 'VARIANT_CHANGE'
}

/** The connection type for Order. */
export type OrderConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export enum OrderFulfillmentKind {
  /** home_delivery order fulfillment kind */
  home_delivery = 'home_delivery',
  /** hub_pickup_appointment order fulfillment kind */
  hub_pickup_appointment = 'hub_pickup_appointment',
  /** hub_pickup_test_ride_conversion order fulfillment kind */
  hub_pickup_test_ride_conversion = 'hub_pickup_test_ride_conversion',
  /** hub_pickup_walk_in order fulfillment kind */
  hub_pickup_walk_in = 'hub_pickup_walk_in',
  /** office_delivery order fulfillment kind */
  office_delivery = 'office_delivery'
}

export enum OrderKind {
  /** subscription order kind */
  subscription = 'subscription',
  /** swap order kind */
  swap = 'swap'
}

export enum OrderSortColumn {
  ClosedAt = 'closedAt',
  CreatedAt = 'createdAt',
  email = 'email',
  kind = 'kind',
  OfficeName = 'officeName',
  OrganizationName = 'organizationName',
  ReadiedAt = 'readiedAt',
  state = 'state',
  StateWeight = 'stateWeight',
  vehicle = 'vehicle'
}

export enum OrderState {
  /** canceled order state */
  canceled = 'canceled',
  /** fulfilled order state */
  fulfilled = 'fulfilled',
  /** on_hold order state */
  on_hold = 'on_hold',
  /** open order state */
  open = 'open',
  /** ready order state */
  ready = 'ready'
}

export enum OrderSwapReason {
  /** converted_cancellation order swap reason */
  ConvertedCancellation = 'CONVERTED_CANCELLATION',
  /** did_not_fit_on_transit order swap reason */
  DidNotFitOnTransit = 'DID_NOT_FIT_ON_TRANSIT',
  /** other order swap reason */
  Other = 'OTHER',
  /** unknown order swap reason */
  Unknown = 'UNKNOWN',
  /** vehicle_is_wrong_size order swap reason */
  VehicleIsWrongSize = 'VEHICLE_IS_WRONG_SIZE',
  /** vehicle_too_heavy order swap reason */
  VehicleTooHeavy = 'VEHICLE_TOO_HEAVY',
  /** want_lower_monthly_payment order swap reason */
  WantLowerMonthlyPayment = 'WANT_LOWER_MONTHLY_PAYMENT',
  /** want_more_power order swap reason */
  WantMorePower = 'WANT_MORE_POWER',
  /** want_more_range order swap reason */
  WantMoreRange = 'WANT_MORE_RANGE',
  /** want_room_for_cargo order swap reason */
  WantRoomForCargo = 'WANT_ROOM_FOR_CARGO'
}

export type OrderValidationResult = {
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  valid: Scalars['Boolean'];
};

export type Organization = {
  allowedDomains: Array<Scalars['String']>;
  allowsPersonalEmail: Scalars['Boolean'];
  authorizedEmailDomainsEnabled: Scalars['Boolean'];
  authorizedEmails: Scalars['String'];
  authorizedEmailsEnabled: Scalars['Boolean'];
  benefitsText?: Maybe<Scalars['String']>;
  benefitsTextEnabled: Scalars['Boolean'];
  benefitsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryCopyHubIds?: Maybe<Array<Scalars['String']>>;
  discoverable: Scalars['Boolean'];
  faqs: Array<Faq>;
  historicalHubs: Array<Hub>;
  id: Scalars['ID'];
  /** @deprecated use `terms_and_conditions_url` */
  leasingTermsAndConditionsUrl?: Maybe<Scalars['String']>;
  locations: Array<Location>;
  logo?: Maybe<Image>;
  metadata?: Maybe<OrganizationMetadata>;
  name: Scalars['String'];
  offices: Array<Office>;
  organizationAdmins: Array<OrganizationAdmin>;
  organizationHubs: Array<OrganizationHub>;
  slug: Scalars['String'];
  spokeFlowEnabled: Scalars['Boolean'];
  subsidy: OrganizationSubsidy;
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  theftInsuranceEnabled: Scalars['Boolean'];
};

export type OrganizationAdmin = {
  email: Scalars['String'];
  id: Scalars['ID'];
  organization: Organization;
};

/** The connection type for OrganizationAdmin. */
export type OrganizationAdminConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationAdminEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrganizationAdmin>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrganizationAdminEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrganizationAdmin>;
};

export type OrganizationAdminInput = {
  /** Admin User email */
  email: Scalars['String'];
  /** Admin User ID */
  id?: InputMaybe<Scalars['ID']>;
};

export type OrganizationHub = {
  hub: Hub;
  id: Scalars['ID'];
  organization: Organization;
  vehicleCategories: Array<VehicleCategory>;
};

export type OrganizationInput = {
  /** List of admin user emails */
  adminEmails?: InputMaybe<Array<Scalars['String']>>;
  /** The list of allowed domains users can login with this organization */
  allowedDomains?: InputMaybe<Array<Scalars['String']>>;
  /** If enabled, checkout will allow personal emails */
  allowsPersonalEmail?: InputMaybe<Scalars['Boolean']>;
  /** If enabled, checkout requires user email domain to match organization allowed domains list */
  authorizedEmailDomainsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** If enabled, checkout requires user email to match organization authorized emails list */
  authorizedEmailsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The benefits text shown on Portal */
  benefitsText?: InputMaybe<Scalars['String']>;
  /** If enabled, this organization will show the benefits text on Portal */
  benefitsTextEnabled?: InputMaybe<Scalars['Boolean']>;
  /** List of Hub IDs for displaying custom delivery copy on Portal */
  deliveryCopyHubIds?: InputMaybe<Array<Scalars['ID']>>;
  /** If enabled, this organization will appear in the portal organization selector */
  discoverable?: InputMaybe<Scalars['Boolean']>;
  /** List of FAQs shown on Portal */
  faqs?: InputMaybe<Array<FaqInput>>;
  /** The locations to associate the organization with */
  locations?: InputMaybe<Array<Scalars['ID']>>;
  /** The logo of the organization */
  logo?: InputMaybe<MediaInput>;
  /** The metadata of the organization */
  metadata?: InputMaybe<OrganizationMetadataInput>;
  /** The name of the organization */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the organization */
  slug?: InputMaybe<Scalars['String']>;
  /** If enabled, this organization will show the spoke user flow in the portal */
  spokeFlowEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The invoice credit amount to apply when subsidies are enabled */
  subsidy?: InputMaybe<OrganizationSubsidyInput>;
  /** The Terms and Conditions URL for the organization */
  termsAndConditionsUrl?: InputMaybe<Scalars['Url']>;
  /** If enabled, portal will show theft insurance language for this organization */
  theftInsuranceEnabled?: InputMaybe<Scalars['Boolean']>;
  /** List of the Organization's vehicle categories by hub */
  vehicleCategoriesByHub?: InputMaybe<Array<HubVehicleCategoriesInput>>;
};

export type OrganizationMetadata = {
  benefitsCardFunded?: Maybe<Scalars['ISO8601Date']>;
  customerSuccessManager?: Maybe<Scalars['String']>;
  expectedFirstOrder?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  mainContact?: Maybe<Scalars['String']>;
  marketingLaunch?: Maybe<Scalars['ISO8601Date']>;
  marketingStrategyInfo?: Maybe<Scalars['String']>;
  organization: Organization;
  platform?: Maybe<Scalars['String']>;
  signoffApprovalByPartner: Scalars['Boolean'];
  signoffApprovalByPartnerDate?: Maybe<Scalars['ISO8601Date']>;
  totalPotentialEmployees?: Maybe<Scalars['Int']>;
};

export type OrganizationMetadataInput = {
  /** Date when the benefits card is funded */
  benefitsCardFunded?: InputMaybe<Scalars['ISO8601Date']>;
  /** Organization's customer success manager */
  customerSuccessManager?: InputMaybe<Scalars['String']>;
  /** Date when the marketing launch is live */
  expectedFirstOrder?: InputMaybe<Scalars['ISO8601Date']>;
  /** Organization's main contact */
  mainContact?: InputMaybe<Scalars['String']>;
  /** Platform the organization belongs to */
  marketingLaunch?: InputMaybe<Scalars['ISO8601Date']>;
  /** Marketing notes for the marketing strategy */
  marketingStrategyInfo?: InputMaybe<Scalars['String']>;
  /** Platform the organization belongs to */
  platform?: InputMaybe<Scalars['String']>;
  /** Whether the partner has approved signoff */
  signoffApprovalByPartner: Scalars['Boolean'];
  /** Date when the partner signoff is approved */
  signoffApprovalByPartnerDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Organization's total potential employees */
  totalPotentialEmployees?: InputMaybe<Scalars['Int']>;
};

export type OrganizationSubsidy = {
  amount?: Maybe<Scalars['Float']>;
  cap?: Maybe<Scalars['Float']>;
  enabled: Scalars['Boolean'];
  process?: Maybe<OrganizationSubsidyProcess>;
};

export type OrganizationSubsidyInput = {
  /** Percent or dollar subsidy amount based on type */
  amount?: InputMaybe<Scalars['Float']>;
  /** Maximum amount for a percentage subsidy */
  cap?: InputMaybe<Scalars['Float']>;
  /** Whether the subsidy is enabled */
  enabled: Scalars['Boolean'];
  /** `fixed` or `percentage` */
  process?: InputMaybe<OrganizationSubsidyProcess>;
};

export enum OrganizationSubsidyProcess {
  /** fixed dollar subsidy */
  fixed = 'fixed',
  /** percentage subsidy */
  percentage = 'percentage'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  currentPage: Scalars['Int'];
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaidInvoice = {
  brand: Scalars['String'];
  color: Scalars['String'];
  email: Scalars['String'];
  firstMonth: Scalars['Boolean'];
  invoiceDate: Scalars['ISO8601Date'];
  leaseCancelDate?: Maybe<Scalars['ISO8601Date']>;
  leaseStartDate?: Maybe<Scalars['ISO8601Date']>;
  model: Scalars['String'];
  number: Scalars['String'];
  officeName: Scalars['String'];
  paidDate: Scalars['ISO8601Date'];
  size: Scalars['String'];
  state: Scalars['String'];
  swapped: Scalars['Boolean'];
  total: Scalars['Decimal'];
};

export type PaymentDetails = {
  planPriceCents?: Maybe<Scalars['Int']>;
  subsidy?: Maybe<OrganizationSubsidy>;
  subtotalCents?: Maybe<Scalars['Int']>;
  taxCents?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<TaxRate>;
  totalCents?: Maybe<Scalars['Int']>;
};

export type Plan = {
  id: Scalars['ID'];
  postSubsidyPrice: Money;
  price: Money;
  vehicle: Vehicle;
};

export type Promotion = {
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  ordersCount: Scalars['Int'];
  organization?: Maybe<Organization>;
  status: PromotionStatus;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Promotion. */
export type PromotionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PromotionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Promotion>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PromotionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Promotion>;
};

/** The input fields used to create or update a Promotion record */
export type PromotionInput = {
  /** The code of the promotion */
  code: Scalars['String'];
  /** The description of the promotion */
  description: Scalars['String'];
  /** The ID of the organization. If null, promotion applies to all orgs */
  organizationId?: InputMaybe<Scalars['ID']>;
  /** The title of the promotion */
  title: Scalars['String'];
};

export enum PromotionSortColumn {
  code = 'code',
  CreatedAt = 'createdAt',
  description = 'description',
  status = 'status',
  title = 'title'
}

export enum PromotionStatus {
  /** active promotion status */
  Active = 'ACTIVE',
  /** paused promotion status */
  Paused = 'PAUSED'
}

export type PurchaseOrder = {
  additionalCostsCents: Scalars['Int'];
  assets: Array<Asset>;
  checkedInAt?: Maybe<Scalars['ISO8601DateTime']>;
  closedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  directShip: Scalars['Boolean'];
  directShipOrder?: Maybe<Order>;
  discountPercentage: Scalars['Float'];
  hub: Hub;
  id: Scalars['ID'];
  invoiceDoc?: Maybe<Media>;
  mediaAttachment?: Maybe<MediaAttachment>;
  notes?: Maybe<Scalars['String']>;
  orders: Array<Order>;
  purchaseOrderNumber: Scalars['Int'];
  salesOrderNumber?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['ISO8601DateTime']>;
  shippingCostsCents: Scalars['Int'];
  state: PurchaseOrderState;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  trackingInfo?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor: Vendor;
};

/** The connection type for PurchaseOrder. */
export type PurchaseOrderConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PurchaseOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PurchaseOrder>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PurchaseOrderEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PurchaseOrder>;
};

export type PurchaseOrderInput = {
  /** The additional costs of the purchase order, in cents */
  additionalCostsCents?: InputMaybe<Scalars['Int']>;
  /** The assets of the purchase order */
  assets?: InputMaybe<Array<AssetInput>>;
  /** Whether the purchase order is direct shipped or not */
  directShip?: InputMaybe<Scalars['Boolean']>;
  /** The discount percentage of the purchase order */
  discountPercentage?: InputMaybe<Scalars['Float']>;
  /** The hub ID to associate the purchase order with */
  hubId: Scalars['ID'];
  /** The invoice doc URL of the purchase order */
  invoiceDocUrl?: InputMaybe<Scalars['String']>;
  /** The notes of the purchase order */
  notes?: InputMaybe<Scalars['String']>;
  /** The sales order number of the purchase order */
  salesOrderNumber?: InputMaybe<Scalars['String']>;
  /** The shipping costs of the purchase order, in cents */
  shippingCostsCents?: InputMaybe<Scalars['Int']>;
  /** The tracking info of the purchase order */
  trackingInfo?: InputMaybe<Scalars['String']>;
  /** The vendor ID to associate the purchase order with */
  vendorId: Scalars['ID'];
};

export enum PurchaseOrderSortColumn {
  AssetsCount = 'assetsCount',
  CheckedInAt = 'checkedInAt',
  ClosedAt = 'closedAt',
  CreatedAt = 'createdAt',
  HubName = 'hubName',
  PurchaseOrderNumber = 'purchaseOrderNumber',
  SalesOrderNumber = 'salesOrderNumber',
  state = 'state',
  VendorName = 'vendorName'
}

export enum PurchaseOrderState {
  /** check_in purchase order state */
  CheckIn = 'CHECK_IN',
  /** closed purchase order state */
  Closed = 'CLOSED',
  /** open purchase order state */
  Open = 'OPEN',
  /** shipped purchase order state */
  Shipped = 'SHIPPED',
  /** submitted purchase order state */
  Submitted = 'SUBMITTED'
}

export type PurchaseOrderTabs = {
  checkedInAssets: Array<AssetsForPoTab>;
  orderedAssets: Array<AssetsForPoTab>;
  overviewAssets: Array<AssetsForPoTab>;
  shippedAssets: Array<AssetsForPoTab>;
};

export type Query = {
  activeRidersMetrics: AnalyticsActiveRidersMetrics;
  asset: Asset;
  assetValidateCheckIn: ValidationResult;
  assets: AssetConnection;
  assetsAvailable?: Maybe<Array<Asset>>;
  checkoutData: HubCheckoutData;
  checkoutSuccess: TransactionResult;
  currentAccessLevels: Array<UserAccessLevel>;
  customer: Customer;
  customers: CustomerConnection;
  featureEnabled: Scalars['Boolean'];
  featureEnabledForHub: Scalars['Boolean'];
  forestUser?: Maybe<User>;
  forestUsers: UserConnection;
  hub?: Maybe<Hub>;
  hubVariants: Array<HubVariant>;
  hubs: Array<Hub>;
  invoices: Array<Invoice>;
  leaseToOwnInvoice?: Maybe<LeaseToOwnInvoice>;
  leaseToOwnInvoices?: Maybe<LeaseToOwnInvoiceConnection>;
  leaseToOwnLease?: Maybe<LeaseToOwnLease>;
  location?: Maybe<Location>;
  locations: Array<Location>;
  logHistories?: Maybe<Array<LogHistory>>;
  notes: Array<Note>;
  office?: Maybe<Office>;
  officeVehicles: Array<Vehicle>;
  offices: OfficeConnection;
  order?: Maybe<Order>;
  orders: OrderConnection;
  organization: Organization;
  organizationAdmin?: Maybe<OrganizationAdmin>;
  organizationAdmins: OrganizationAdminConnection;
  organizations: Array<Organization>;
  paidInvoicesReport: Array<PaidInvoice>;
  portalActivityMetrics: AnalyticsActivityMetrics;
  promotion?: Maybe<Promotion>;
  promotions: PromotionConnection;
  purchaseOrder?: Maybe<PurchaseOrder>;
  purchaseOrderTabs?: Maybe<PurchaseOrderTabs>;
  purchaseOrders: PurchaseOrderConnection;
  reportExport?: Maybe<ReportExport>;
  serviceArea?: Maybe<ServiceArea>;
  serviceAreas: ServiceAreaConnection;
  /** @deprecated Use `lease_to_own_invoice` instead */
  spokeInvoice?: Maybe<LeaseToOwnInvoice>;
  /** @deprecated Use `lease_to_own_invoices` instead */
  spokeInvoices?: Maybe<LeaseToOwnInvoiceConnection>;
  /** @deprecated Use `lease_to_own_lease` instead */
  spokeLease?: Maybe<LeaseToOwnLease>;
  subscription?: Maybe<Subscription>;
  subscriptions: SubscriptionConnection;
  validateEmail: ValidationResult;
  validatePhone: ValidationResult;
  validateVariantCanCheckOutAtHub: ValidationResult;
  variantStats: Array<VariantStat>;
  variantStatsByPurchaseOrder: Array<VariantStat>;
  vehicle?: Maybe<Vehicle>;
  vehicleFulfillmentTime?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Array<Vehicle>>;
  vendor?: Maybe<Vendor>;
  vendorVehicles: Array<VendorVehicle>;
  vendors: VendorConnection;
};


export type QueryActiveRidersMetricsArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  projectUntil?: InputMaybe<Scalars['ISO8601Date']>;
};


export type QueryAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAssetValidateCheckInArgs = {
  id: Scalars['ID'];
  serialNumber: Scalars['String'];
};


export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubId?: InputMaybe<Scalars['ID']>;
  includeActiveSubscription?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<AssetSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryAssetsAvailableArgs = {
  orderId: Scalars['String'];
};


export type QueryCheckoutDataArgs = {
  token: Scalars['String'];
};


export type QueryCheckoutSuccessArgs = {
  email: Scalars['String'];
};


export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  leaseToOwnOnly?: InputMaybe<Scalars['Boolean']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<CustomerSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryFeatureEnabledArgs = {
  flag: Scalars['String'];
};


export type QueryFeatureEnabledForHubArgs = {
  flag: Scalars['String'];
  hubId: Scalars['ID'];
};


export type QueryForestUserArgs = {
  id: Scalars['ID'];
};


export type QueryForestUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryHubArgs = {
  id: Scalars['ID'];
};


export type QueryHubVariantsArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  variantId?: InputMaybe<Scalars['ID']>;
};


export type QueryInvoicesArgs = {
  includeRefunded?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLeaseToOwnInvoiceArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLeaseToOwnInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<LeaseToOwnInvoicesSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryLeaseToOwnLeaseArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  includeCanceled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLocationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryLocationsArgs = {
  excludeWithoutHub?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLogHistoriesArgs = {
  loggableId: Scalars['ID'];
  loggableType: Scalars['String'];
};


export type QueryNotesArgs = {
  notableId: Scalars['ID'];
  notableType: Scalars['String'];
};


export type QueryOfficeArgs = {
  includeUndiscoverableOrgs?: InputMaybe<Scalars['Boolean']>;
  input: OfficeQueryInput;
};


export type QueryOfficeVehiclesArgs = {
  officeSlug: Scalars['String'];
  organizationSlug: Scalars['String'];
};


export type QueryOfficesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
  includeCanceled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubIds?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<OrderSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationAdminArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationAdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryPaidInvoicesReportArgs = {
  endDate: Scalars['ISO8601DateTime'];
  startDate: Scalars['ISO8601DateTime'];
};


export type QueryPortalActivityMetricsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  hubId?: InputMaybe<Scalars['ID']>;
  officeId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};


export type QueryPromotionArgs = {
  id: Scalars['ID'];
};


export type QueryPromotionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<PromotionSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryPurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderTabsArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubIds?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<PurchaseOrderSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryReportExportArgs = {
  id: Scalars['ID'];
};


export type QueryServiceAreaArgs = {
  id: Scalars['ID'];
};


export type QueryServiceAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<ServiceAreaSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QuerySpokeInvoiceArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySpokeInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<LeaseToOwnInvoicesSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QuerySpokeLeaseArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  includeCanceled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubIds?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<SubscriptionSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryValidateEmailArgs = {
  email: Scalars['String'];
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryValidatePhoneArgs = {
  phone: Scalars['String'];
};


export type QueryValidateVariantCanCheckOutAtHubArgs = {
  hubId: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type QueryVariantStatsArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  onlyNeeded?: InputMaybe<Scalars['Boolean']>;
  vendorIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryVariantStatsByPurchaseOrderArgs = {
  onlyNeeded?: InputMaybe<Scalars['Boolean']>;
  purchaseOrderId: Scalars['ID'];
};


export type QueryVehicleArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryVehicleFulfillmentTimeArgs = {
  hubId: Scalars['ID'];
  vehicleId: Scalars['ID'];
};


export type QueryVehiclesArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  onlyAvailable?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVendorArgs = {
  id: Scalars['ID'];
};


export type QueryVendorVehiclesArgs = {
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ReportExport = {
  createdAt: Scalars['ISO8601DateTime'];
  downloadUrl?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  id: Scalars['ID'];
  reportType: Scalars['String'];
  requester: User;
  status: ExportReportStatus;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A user-readable error */
export type ResourceError = {
  /** Resource identifier */
  id?: Maybe<Scalars['ID']>;
  /** A list of descriptive errors */
  messages: Array<Scalars['String']>;
};

export enum RidepandaProgramKind {
  /** Direct ship program kind */
  DirectShip = 'DIRECT_SHIP',
  /** Hub program kind */
  Hub = 'HUB',
  /** Spoke program kind */
  Spoke = 'SPOKE'
}

export enum SalesChannel {
  /** craigslist sales channel */
  Craigslist = 'CRAIGSLIST',
  /** fb_marketplace sales channel */
  FbMarketplace = 'FB_MARKETPLACE',
  /** other sales channel */
  Other = 'OTHER',
  /** subscription_buyout sales channel */
  SubscriptionBuyout = 'SUBSCRIPTION_BUYOUT',
  /** upway sales channel */
  Upway = 'UPWAY'
}

export type ServiceArea = {
  directShipProgramAvailable: Scalars['Boolean'];
  hub?: Maybe<Hub>;
  hubProgramAvailable: Scalars['Boolean'];
  id: Scalars['ID'];
  leaseToOwns: Array<LeaseToOwn>;
  name: Scalars['String'];
  offices: Array<Office>;
  officesCount: Scalars['Int'];
  organizationsCount: Scalars['Int'];
  programsAvailable: Array<RidepandaProgramKind>;
  spokeProgramAvailable: Scalars['Boolean'];
  /** @deprecated use `leaseToOwns` instead */
  spokes: Array<LeaseToOwn>;
};

/** The connection type for ServiceArea. */
export type ServiceAreaConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ServiceAreaEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ServiceArea>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ServiceAreaEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ServiceArea>;
};

/** The input fields used to create or update a Service Area record */
export type ServiceAreaInput = {
  /** The name of the service area */
  name: Scalars['String'];
};

export enum ServiceAreaSortColumn {
  CreatedAt = 'createdAt',
  name = 'name'
}

export enum SortDirection {
  /** ascending */
  asc = 'asc',
  /** descending */
  desc = 'desc'
}

export enum StandardizedSize {
  /** 'L' variant standardized size */
  L = 'L',
  /** 'M' variant standardized size */
  M = 'M',
  /** 'ONE SIZE' variant standardized size */
  OneSize = 'ONE_SIZE',
  /** 'S' variant standardized size */
  S = 'S',
  /** 'XL' variant standardized size */
  Xl = 'XL',
  /** 'XS' variant standardized size */
  Xs = 'XS'
}

export type Subscription = {
  asset?: Maybe<Asset>;
  cancelationNotes?: Maybe<Scalars['String']>;
  cancelationReason?: Maybe<SubscriptionCancelationReason>;
  comments: Array<Note>;
  createdAt: Scalars['ISO8601DateTime'];
  currentPeriodEnd?: Maybe<Scalars['ISO8601DateTime']>;
  currentPeriodStart?: Maybe<Scalars['ISO8601DateTime']>;
  customer: Customer;
  /** @deprecated use `ended_at` instead */
  endDate?: Maybe<Scalars['ISO8601Date']>;
  endedAt?: Maybe<Scalars['ISO8601DateTime']>;
  events?: Maybe<Array<Event>>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  organization: Organization;
  paymentDetails: PaymentDetails;
  plan: Plan;
  /** waive if more than 12 months have passed since start */
  restockFeeWaived: Scalars['Boolean'];
  /** @deprecated use `started_at` instead */
  startDate?: Maybe<Scalars['ISO8601Date']>;
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: SubscriptionState;
  stateUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  variant: Variant;
};

/** The input fields used to cancel a Subscription. */
export type SubscriptionCancelInput = {
  /** Admin notes for the subscription cancelation */
  cancelationNotes?: InputMaybe<Scalars['String']>;
  /** Cancelation reason of the subscription */
  cancelationReason: SubscriptionCancelationReason;
  /** Whether to charge restock fee via Stripe */
  chargeRestockFee?: InputMaybe<Scalars['Boolean']>;
  /** The description of the maintenance if any */
  maintenanceNotes?: InputMaybe<Scalars['String']>;
  /** Serial number of the asset linked to the subscription */
  serialNumber?: InputMaybe<Scalars['String']>;
};

export enum SubscriptionCancelationReason {
  /** benefit_change subscription cancelation reason */
  BenefitChange = 'BENEFIT_CHANGE',
  /** bought_own_vehicle subscription cancelation reason */
  BoughtOwnVehicle = 'BOUGHT_OWN_VEHICLE',
  /** buyout subscription cancelation reason */
  Buyout = 'BUYOUT',
  /** commute_conditions_have_changed subscription cancelation reason */
  CommuteConditionsHaveChanged = 'COMMUTE_CONDITIONS_HAVE_CHANGED',
  /** commute_distance_has_increased subscription cancelation reason */
  CommuteDistanceHasIncreased = 'COMMUTE_DISTANCE_HAS_INCREASED',
  /** declined_to_answer subscription cancelation reason */
  DeclinedToAnswer = 'DECLINED_TO_ANSWER',
  /** did_not_fit_on_transit subscription cancelation reason */
  DidNotFitOnTransit = 'DID_NOT_FIT_ON_TRANSIT',
  /** feel_unsafe_commuting subscription cancelation reason */
  FeelUnsafeCommuting = 'FEEL_UNSAFE_COMMUTING',
  /** injury_medical_pregnancy subscription cancelation reason */
  InjuryMedicalPregnancy = 'INJURY_MEDICAL_PREGNANCY',
  /** internship_ended subscription cancelation reason */
  InternshipEnded = 'INTERNSHIP_ENDED',
  /** leaving_employer subscription cancelation reason */
  LeavingEmployer = 'LEAVING_EMPLOYER',
  /** moving_location subscription cancelation reason */
  MovingLocation = 'MOVING_LOCATION',
  /** not_enough_power subscription cancelation reason */
  NotEnoughPower = 'NOT_ENOUGH_POWER',
  /** not_enough_range subscription cancelation reason */
  NotEnoughRange = 'NOT_ENOUGH_RANGE',
  /** not_using_vehicle subscription cancelation reason */
  NotUsingVehicle = 'NOT_USING_VEHICLE',
  /** other subscription cancelation reason */
  Other = 'OTHER',
  /** price_too_high subscription cancelation reason */
  PriceTooHigh = 'PRICE_TOO_HIGH',
  /** stolen subscription cancelation reason */
  Stolen = 'STOLEN',
  /**
   * terminated subscription cancelation reason
   * @deprecated not used anymore
   */
  Terminated = 'TERMINATED',
  /** terrain_is_challenging subscription cancelation reason */
  TerrainIsChallenging = 'TERRAIN_IS_CHALLENGING',
  /** transition_to_other_micromobility_vendor subscription cancelation reason */
  TransitionToOtherMicromobilityVendor = 'TRANSITION_TO_OTHER_MICROMOBILITY_VENDOR',
  /** transition_to_public_transit_rideshare subscription cancelation reason */
  TransitionToPublicTransitRideshare = 'TRANSITION_TO_PUBLIC_TRANSIT_RIDESHARE',
  /** unknown subscription cancelation reason */
  Unknown = 'UNKNOWN',
  /** unsatisfied_with_program_terms subscription cancelation reason */
  UnsatisfiedWithProgramTerms = 'UNSATISFIED_WITH_PROGRAM_TERMS',
  /** vehicle_is_wrong_size subscription cancelation reason */
  VehicleIsWrongSize = 'VEHICLE_IS_WRONG_SIZE',
  /**
   * vehicle_not_a_fit subscription cancelation reason
   * @deprecated not used anymore
   */
  VehicleNotAFit = 'VEHICLE_NOT_A_FIT',
  /** weather subscription cancelation reason */
  Weather = 'WEATHER'
}

/** The connection type for Subscription. */
export type SubscriptionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubscriptionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Subscription>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SubscriptionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Subscription>;
};

export enum SubscriptionSortColumn {
  CreatedAt = 'createdAt',
  email = 'email',
  OfficeName = 'officeName',
  OrganizationName = 'organizationName',
  /** @deprecated use `startedAt` instead */
  StartDate = 'startDate',
  StartedAt = 'startedAt',
  state = 'state',
  VehicleName = 'vehicleName'
}

export enum SubscriptionState {
  /** active subscription state */
  active = 'active',
  /** canceled subscription state */
  canceled = 'canceled'
}

/** The input fields used to update a Subscription. */
export type SubscriptionUpdateInput = {
  /** True if the subscription needs review by an admin */
  needsReview?: InputMaybe<Scalars['Boolean']>;
  /** Admin notes for the subscription */
  notes?: InputMaybe<Scalars['String']>;
};

export type TaxRate = {
  /** The name of the tax rate */
  name?: Maybe<Scalars['String']>;
  /** The percentage of the tax rate */
  percentage?: Maybe<Scalars['Float']>;
};

export type TheftReport = {
  asset: Asset;
  createdAt: Scalars['ISO8601DateTime'];
  deductibleAmountCents?: Maybe<Scalars['Int']>;
  documents: Array<Media>;
  id: Scalars['ID'];
  insuranceClaimId?: Maybe<Scalars['String']>;
  insuranceProceedsDate?: Maybe<Scalars['ISO8601Date']>;
  proceedsCents?: Maybe<Scalars['Int']>;
  stripeDeductiblePaymentId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TheftReportInput = {
  /** The ID of the asset to create the theft report for */
  assetId: Scalars['ID'];
  /** The deductible amount, in cents */
  deductibleAmountCents: Scalars['Int'];
  /** Documents to attach to the theft report */
  documents?: InputMaybe<Array<MediaInput>>;
  /** The ID of the insurance claim */
  insuranceClaimId: Scalars['String'];
  /** The insurance proceeds date */
  insuranceProceedsDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** The proceeds amount, in cents */
  proceedsCents: Scalars['Int'];
  /** The ID of the stripe payment */
  stripeDeductiblePaymentId: Scalars['String'];
};

export type TransactionResult = {
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type User = {
  accessLevels: Array<UserAccessLevel>;
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  hub?: Maybe<Hub>;
  id: Scalars['ID'];
};

export enum UserAccessLevel {
  /** customer access level */
  Customer = 'CUSTOMER',
  /** forest_admin access level */
  ForestAdmin = 'FOREST_ADMIN',
  /** organization_admin access level */
  OrganizationAdmin = 'ORGANIZATION_ADMIN'
}

/** The connection type for User. */
export type UserConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type ValidationResult = {
  error?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type Variant = {
  brand: Scalars['String'];
  color: Scalars['String'];
  colorCode: Scalars['ColorCode'];
  description: Scalars['String'];
  headerImage?: Maybe<Image>;
  hubVariants: Array<HubVariant>;
  id: Scalars['ID'];
  image: Image;
  inStock: Scalars['Boolean'];
  maxHeight?: Maybe<Scalars['String']>;
  minHeight?: Maybe<Scalars['String']>;
  model: Scalars['String'];
  /** The size and heights of the variant */
  size: Scalars['String'];
  standardizedSize: StandardizedSize;
  vehicle: Vehicle;
};

export type VariantInput = {
  /** Variant color */
  color?: InputMaybe<Scalars['String']>;
  /** Variant color hex code */
  colorCode?: InputMaybe<Scalars['ColorCode']>;
  /** Image to use for the riders page vehicles card */
  headerImage?: InputMaybe<MediaInput>;
  /** Variant id. passing null will create a new variant */
  id?: InputMaybe<Scalars['ID']>;
  /** Image to use for the variant */
  image?: InputMaybe<MediaInput>;
  /** Variant max height */
  maxHeight?: InputMaybe<Scalars['String']>;
  /** Variant min height */
  minHeight?: InputMaybe<Scalars['String']>;
  /** Variant standardized size */
  standardizedSize?: InputMaybe<StandardizedSize>;
};

export type VariantStat = {
  /** Returns null when cannnot be determined */
  allowBackorders?: Maybe<Scalars['Boolean']>;
  available: Scalars['Int'];
  brand: Scalars['String'];
  color: Scalars['String'];
  decommissioned: Scalars['Int'];
  description: Scalars['String'];
  handoff: Scalars['Int'];
  maintenanceHold: Scalars['Int'];
  model: Scalars['String'];
  needed: Scalars['Int'];
  openOrders: Scalars['Int'];
  openPo: Scalars['Int'];
  received: Scalars['Int'];
  returned: Scalars['Int'];
  size: Scalars['String'];
  sold: Scalars['Int'];
  stolen: Scalars['Int'];
  subscription: Scalars['Int'];
  testRides: Scalars['Int'];
  total: Scalars['Int'];
  variantId: Scalars['ID'];
  /** The vehicle wholesale price reference, in cents */
  wholesalePriceCents: Scalars['Int'];
};

export type Vehicle = {
  allowBuyout: Scalars['Boolean'];
  brand: Scalars['String'];
  category: VehicleCategory;
  chargeTime?: Maybe<Scalars['String']>;
  chargeTimeHours?: Maybe<FloatRange>;
  /** If enabled, vehicle will display as a core model */
  coreModel: Scalars['Boolean'];
  details?: Maybe<Scalars['String']>;
  highlight1?: Maybe<Scalars['String']>;
  highlight2?: Maybe<Scalars['String']>;
  highlight3?: Maybe<Scalars['String']>;
  highlights?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  images?: Maybe<Array<Image>>;
  inStock: Scalars['Boolean'];
  kind?: Maybe<VehicleKind>;
  maxLoad?: Maybe<Scalars['String']>;
  maxLoadLbs?: Maybe<Scalars['Int']>;
  model: Scalars['String'];
  msrpCents?: Maybe<Scalars['Int']>;
  plan?: Maybe<Plan>;
  primaryVariant?: Maybe<Variant>;
  range?: Maybe<Scalars['String']>;
  rangeMiles?: Maybe<Scalars['Int']>;
  recommendationAttribution?: Maybe<Scalars['String']>;
  recommendationQuote?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  stripeProductId: Scalars['String'];
  timeToFulfillWeeks: Scalars['String'];
  topSpeed?: Maybe<Scalars['String']>;
  topSpeedMph?: Maybe<Scalars['Int']>;
  variants: Array<Variant>;
  weight?: Maybe<Scalars['String']>;
  weightLbs?: Maybe<Scalars['Int']>;
  wholesalePriceCents?: Maybe<Scalars['Int']>;
};

export enum VehicleCategory {
  /** ebike */
  Ebike = 'EBIKE',
  /** escooter */
  Escooter = 'ESCOOTER',
  /** pedal_bike */
  PedalBike = 'PEDAL_BIKE',
  /** test */
  Test = 'TEST'
}

export enum VehicleKind {
  /** cargo vehicle kind */
  Cargo = 'CARGO',
  /** commuter vehicle kind */
  Commuter = 'COMMUTER',
  /** folding vehicle kind */
  Folding = 'FOLDING',
  /** mountain vehicle kind */
  Mountain = 'MOUNTAIN',
  /** road vehicle kind */
  Road = 'ROAD',
  /** scooter vehicle kind */
  Scooter = 'SCOOTER'
}

export type VehicleUpdateInput = {
  /** If the vehicle is allowed to be bought out */
  allowBuyout?: InputMaybe<Scalars['Boolean']>;
  /** list of allowed hub ids */
  allowedHubIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Vehicle brand */
  brand?: InputMaybe<Scalars['String']>;
  /** Vehicle category */
  category?: InputMaybe<VehicleCategory>;
  /** charge time hours range */
  chargeTimeHours?: InputMaybe<FloatRangeInput>;
  /** If enabled, vehicle will display as a core model */
  coreModel?: InputMaybe<Scalars['Boolean']>;
  /** details */
  details?: InputMaybe<Scalars['String']>;
  /** highlight1 */
  highlight1?: InputMaybe<Scalars['String']>;
  /** highlight2 */
  highlight2?: InputMaybe<Scalars['String']>;
  /** highlight3 */
  highlight3?: InputMaybe<Scalars['String']>;
  /** list of images */
  images?: InputMaybe<Array<MediaInput>>;
  /** vehicle kind */
  kind?: InputMaybe<VehicleKind>;
  /** max load in lbs */
  maxLoadLbs?: InputMaybe<Scalars['Int']>;
  /** Vehicle model */
  model?: InputMaybe<Scalars['String']>;
  /** msrp price in cents */
  msrpCents?: InputMaybe<Scalars['Int']>;
  /** Vehicle lease price */
  price?: InputMaybe<MoneyInput>;
  /** the id for the primary variant */
  primaryVariantId?: InputMaybe<Scalars['ID']>;
  /** range in miles */
  rangeMiles?: InputMaybe<Scalars['Int']>;
  /** recommendation attribution */
  recommendationAttribution?: InputMaybe<Scalars['String']>;
  /** recommendation quote */
  recommendationQuote?: InputMaybe<Scalars['String']>;
  /** short description */
  shortDescription?: InputMaybe<Scalars['String']>;
  /** top speed in mph */
  topSpeedMph?: InputMaybe<Scalars['Int']>;
  /** list of variants */
  variants?: InputMaybe<Array<VariantInput>>;
  /** weight in lbs */
  weightLbs?: InputMaybe<Scalars['Int']>;
  /** wholesale price in cents */
  wholesalePriceCents?: InputMaybe<Scalars['Int']>;
};

export type Vendor = {
  id: Scalars['ID'];
  maxTimeToFulfill: Scalars['Int'];
  minTimeToFulfill: Scalars['Int'];
  name: Scalars['String'];
  vehicles: Array<Vehicle>;
};

/** The connection type for Vendor. */
export type VendorConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VendorEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Vendor>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VendorEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Vendor>;
};

export type VendorInput = {
  /** Maximum time to fulfill order */
  maxTimeToFulfill: Scalars['Int'];
  /** Minimum time to fulfill order */
  minTimeToFulfill: Scalars['Int'];
  /** Vendor Name */
  name: Scalars['String'];
  /** Vendor Vehicle IDs */
  vehicleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type VendorVehicle = {
  description: Scalars['String'];
  /** whether the vehicle is enabled for the vendor */
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  /** whether the vehicle is associated to the vendor */
  linked: Scalars['Boolean'];
};

export type GetActiveRidersMetricsQueryVariables = Exact<{
  hubId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
}>;


export type GetActiveRidersMetricsQuery = { activeRidersMetrics: { currentStats: { totalCo2OffsetKg: number, totalMiles: number, treesPlantedEquivalency: number, carTripsAvoided: number, details: Array<{ date: any, ebikeCount: number, escooterCount: number, pedalBikeCount: number, totalCount: number }> }, projectedStats?: { totalCo2OffsetKg: number, totalMiles: number, treesPlantedEquivalency: number, carTripsAvoided: number, details: Array<{ date: any, ebikeCount: number, escooterCount: number, pedalBikeCount: number, totalCount: number }> } | null } };

export type PaidInvoicesReportQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
}>;


export type PaidInvoicesReportQuery = { paidInvoicesReport: Array<{ number: string, email: string, brand: string, model: string, size: string, color: string, state: string, total: any, invoiceDate: any, paidDate: any, leaseStartDate?: any | null, leaseCancelDate?: any | null, firstMonth: boolean, swapped: boolean }> };

export type PortalActivityMetricsQueryVariables = Exact<{
  hubId?: InputMaybe<Scalars['ID']>;
  officeId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
}>;


export type PortalActivityMetricsQuery = { portalActivityMetrics: { currentStats: { date: any, ordersCount: number, sessionsCount: number, uniqueVisitorsCount: number, details: Array<{ date: any, ordersCount: number, sessionsCount: number, uniqueVisitorsCount: number }> }, previousStats: { date: any, ordersCount: number, sessionsCount: number, uniqueVisitorsCount: number } } };

export type InvoiceDocUrlCreateMutationVariables = Exact<{
  prefix: Scalars['String'];
  mimeType: Scalars['String'];
  fileSize: Scalars['BigInt'];
}>;


export type InvoiceDocUrlCreateMutation = { fileUploadUrlCreate?: { httpMethod: string, uploadUrl: any, publicUrl: any, existingFile: boolean } | null };

export type PaidInvoicesExportMutationVariables = Exact<{
  hubId?: InputMaybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
}>;


export type PaidInvoicesExportMutation = { paidInvoicesExport?: { id: string, status: ExportReportStatus } | null };

export type GetCurrentAccessLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentAccessLevelsQuery = { currentAccessLevels: Array<UserAccessLevel> };

export type OfficesQueryVariables = Exact<{
  organizationSlug?: InputMaybe<Scalars['String']>;
}>;


export type OfficesQuery = { offices: { nodes?: Array<{ id: string, name: string, serviceArea: { name: string, hub?: { id: string } | null } } | null> | null } };

export type GetOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationQuery = { organization: { id: string, name: string, slug: string, logo?: { url: any } | null, locations: Array<{ name: string, id: string }>, historicalHubs: Array<{ id: string, name: string }> } };


export const GetActiveRidersMetricsDocument = gql`
    query GetActiveRidersMetrics($hubId: ID, $organizationId: ID) {
  activeRidersMetrics(hubId: $hubId, organizationId: $organizationId) {
    currentStats {
      details {
        date
        ebikeCount
        escooterCount
        pedalBikeCount
        totalCount
      }
      totalCo2OffsetKg
      totalMiles
      treesPlantedEquivalency
      carTripsAvoided
    }
    projectedStats {
      details {
        date
        ebikeCount
        escooterCount
        pedalBikeCount
        totalCount
      }
      totalCo2OffsetKg
      totalMiles
      treesPlantedEquivalency
      carTripsAvoided
    }
  }
}
    `;

/**
 * __useGetActiveRidersMetricsQuery__
 *
 * To run a query within a React component, call `useGetActiveRidersMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveRidersMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveRidersMetricsQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetActiveRidersMetricsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>(GetActiveRidersMetricsDocument, options);
      }
export function useGetActiveRidersMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>(GetActiveRidersMetricsDocument, options);
        }
export type GetActiveRidersMetricsQueryHookResult = ReturnType<typeof useGetActiveRidersMetricsQuery>;
export type GetActiveRidersMetricsLazyQueryHookResult = ReturnType<typeof useGetActiveRidersMetricsLazyQuery>;
export type GetActiveRidersMetricsQueryResult = Apollo.QueryResult<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>;
export const PaidInvoicesReportDocument = gql`
    query PaidInvoicesReport($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
  paidInvoicesReport(startDate: $startDate, endDate: $endDate) {
    number
    email
    brand
    model
    size
    color
    state
    total
    invoiceDate
    paidDate
    leaseStartDate
    leaseCancelDate
    firstMonth
    swapped
  }
}
    `;

/**
 * __usePaidInvoicesReportQuery__
 *
 * To run a query within a React component, call `usePaidInvoicesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaidInvoicesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaidInvoicesReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePaidInvoicesReportQuery(baseOptions: Apollo.QueryHookOptions<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>(PaidInvoicesReportDocument, options);
      }
export function usePaidInvoicesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>(PaidInvoicesReportDocument, options);
        }
export type PaidInvoicesReportQueryHookResult = ReturnType<typeof usePaidInvoicesReportQuery>;
export type PaidInvoicesReportLazyQueryHookResult = ReturnType<typeof usePaidInvoicesReportLazyQuery>;
export type PaidInvoicesReportQueryResult = Apollo.QueryResult<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>;
export const PortalActivityMetricsDocument = gql`
    query PortalActivityMetrics($hubId: ID, $officeId: ID, $organizationId: ID, $startDate: ISO8601Date, $endDate: ISO8601Date) {
  portalActivityMetrics(
    hubId: $hubId
    officeId: $officeId
    organizationId: $organizationId
    startDate: $startDate
    endDate: $endDate
  ) {
    currentStats {
      date
      details {
        date
        ordersCount
        sessionsCount
        uniqueVisitorsCount
      }
      ordersCount
      sessionsCount
      uniqueVisitorsCount
    }
    previousStats {
      date
      ordersCount
      sessionsCount
      uniqueVisitorsCount
    }
  }
}
    `;

/**
 * __usePortalActivityMetricsQuery__
 *
 * To run a query within a React component, call `usePortalActivityMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortalActivityMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortalActivityMetricsQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      officeId: // value for 'officeId'
 *      organizationId: // value for 'organizationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePortalActivityMetricsQuery(baseOptions?: Apollo.QueryHookOptions<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>(PortalActivityMetricsDocument, options);
      }
export function usePortalActivityMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>(PortalActivityMetricsDocument, options);
        }
export type PortalActivityMetricsQueryHookResult = ReturnType<typeof usePortalActivityMetricsQuery>;
export type PortalActivityMetricsLazyQueryHookResult = ReturnType<typeof usePortalActivityMetricsLazyQuery>;
export type PortalActivityMetricsQueryResult = Apollo.QueryResult<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>;
export const InvoiceDocUrlCreateDocument = gql`
    mutation InvoiceDocUrlCreate($prefix: String!, $mimeType: String!, $fileSize: BigInt!) {
  fileUploadUrlCreate(prefix: $prefix, mimeType: $mimeType, fileSize: $fileSize) {
    httpMethod
    uploadUrl
    publicUrl
    existingFile
  }
}
    `;
export type InvoiceDocUrlCreateMutationFn = Apollo.MutationFunction<InvoiceDocUrlCreateMutation, InvoiceDocUrlCreateMutationVariables>;

/**
 * __useInvoiceDocUrlCreateMutation__
 *
 * To run a mutation, you first call `useInvoiceDocUrlCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDocUrlCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceDocUrlCreateMutation, { data, loading, error }] = useInvoiceDocUrlCreateMutation({
 *   variables: {
 *      prefix: // value for 'prefix'
 *      mimeType: // value for 'mimeType'
 *      fileSize: // value for 'fileSize'
 *   },
 * });
 */
export function useInvoiceDocUrlCreateMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceDocUrlCreateMutation, InvoiceDocUrlCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceDocUrlCreateMutation, InvoiceDocUrlCreateMutationVariables>(InvoiceDocUrlCreateDocument, options);
      }
export type InvoiceDocUrlCreateMutationHookResult = ReturnType<typeof useInvoiceDocUrlCreateMutation>;
export type InvoiceDocUrlCreateMutationResult = Apollo.MutationResult<InvoiceDocUrlCreateMutation>;
export type InvoiceDocUrlCreateMutationOptions = Apollo.BaseMutationOptions<InvoiceDocUrlCreateMutation, InvoiceDocUrlCreateMutationVariables>;
export const PaidInvoicesExportDocument = gql`
    mutation PaidInvoicesExport($hubId: ID, $organizationId: ID!, $startDate: ISO8601Date!, $endDate: ISO8601Date!) {
  paidInvoicesExport(
    hubId: $hubId
    organizationId: $organizationId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    status
  }
}
    `;
export type PaidInvoicesExportMutationFn = Apollo.MutationFunction<PaidInvoicesExportMutation, PaidInvoicesExportMutationVariables>;

/**
 * __usePaidInvoicesExportMutation__
 *
 * To run a mutation, you first call `usePaidInvoicesExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaidInvoicesExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paidInvoicesExportMutation, { data, loading, error }] = usePaidInvoicesExportMutation({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      organizationId: // value for 'organizationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePaidInvoicesExportMutation(baseOptions?: Apollo.MutationHookOptions<PaidInvoicesExportMutation, PaidInvoicesExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaidInvoicesExportMutation, PaidInvoicesExportMutationVariables>(PaidInvoicesExportDocument, options);
      }
export type PaidInvoicesExportMutationHookResult = ReturnType<typeof usePaidInvoicesExportMutation>;
export type PaidInvoicesExportMutationResult = Apollo.MutationResult<PaidInvoicesExportMutation>;
export type PaidInvoicesExportMutationOptions = Apollo.BaseMutationOptions<PaidInvoicesExportMutation, PaidInvoicesExportMutationVariables>;
export const GetCurrentAccessLevelsDocument = gql`
    query GetCurrentAccessLevels {
  currentAccessLevels
}
    `;

/**
 * __useGetCurrentAccessLevelsQuery__
 *
 * To run a query within a React component, call `useGetCurrentAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentAccessLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentAccessLevelsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>(GetCurrentAccessLevelsDocument, options);
      }
export function useGetCurrentAccessLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>(GetCurrentAccessLevelsDocument, options);
        }
export type GetCurrentAccessLevelsQueryHookResult = ReturnType<typeof useGetCurrentAccessLevelsQuery>;
export type GetCurrentAccessLevelsLazyQueryHookResult = ReturnType<typeof useGetCurrentAccessLevelsLazyQuery>;
export type GetCurrentAccessLevelsQueryResult = Apollo.QueryResult<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>;
export const OfficesDocument = gql`
    query Offices($organizationSlug: String) {
  offices(organizationSlug: $organizationSlug) {
    nodes {
      id
      name
      serviceArea {
        name
        hub {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useOfficesQuery__
 *
 * To run a query within a React component, call `useOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficesQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useOfficesQuery(baseOptions?: Apollo.QueryHookOptions<OfficesQuery, OfficesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfficesQuery, OfficesQueryVariables>(OfficesDocument, options);
      }
export function useOfficesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficesQuery, OfficesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfficesQuery, OfficesQueryVariables>(OfficesDocument, options);
        }
export type OfficesQueryHookResult = ReturnType<typeof useOfficesQuery>;
export type OfficesLazyQueryHookResult = ReturnType<typeof useOfficesLazyQuery>;
export type OfficesQueryResult = Apollo.QueryResult<OfficesQuery, OfficesQueryVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization {
  organization {
    id
    name
    slug
    logo {
      url
    }
    locations {
      name
      id
    }
    historicalHubs {
      id
      name
    }
  }
}
    `;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;