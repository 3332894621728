import { UserAccessLevel } from "../../api/types-and-hooks";
import { useApp } from "../../contexts/AppContext";
import { Insights } from "../insights";

export default function Home() {
  const { currentAccessLevels } = useApp();

  if (currentAccessLevels.includes(UserAccessLevel.OrganizationAdmin)) {
    return <Insights />;
  }
  return <></>;
}
