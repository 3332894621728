import React, { type ReactNode, useEffect, useState } from "react";
import {
  type GetOrganizationQuery,
  UserAccessLevel,
  useGetCurrentAccessLevelsLazyQuery,
  useGetOrganizationLazyQuery,
} from "../api/types-and-hooks";
import { useAuth } from "../components/AuthProvider";

type Props = {
  children: ReactNode;
};

export type Organization = NonNullable<GetOrganizationQuery["organization"]>;

interface AppContextType {
  organization?: Organization;
  currentAccessLevels: UserAccessLevel[];
}

const AppContext = React.createContext<AppContextType>({} as AppContextType);

export default function AppProvider({ children }: Props) {
  const [fetchOrganization, { data: organizationData }] =
    useGetOrganizationLazyQuery({
      fetchPolicy: "no-cache",
    });
  const [fetchCurrentAccessLevels, { data: currentAccessLevelsData }] =
    useGetCurrentAccessLevelsLazyQuery({
      fetchPolicy: "no-cache",
    });
  const { user: firebaseUser } = useAuth();
  const organization = organizationData?.organization as Organization;
  const currentAccessLevels =
    currentAccessLevelsData?.currentAccessLevels as UserAccessLevel[];

  const value = { currentAccessLevels, organization };

  useEffect(() => {
    if (!firebaseUser) {
      return;
    }
    fetchCurrentAccessLevels();
  }, [firebaseUser, fetchCurrentAccessLevels]);

  useEffect(() => {
    if (!currentAccessLevels) {
      return;
    }

    if (currentAccessLevels.includes(UserAccessLevel.OrganizationAdmin)) {
      fetchOrganization();
    }
  }, [currentAccessLevels, fetchOrganization]);

  return (
    <AppContext.Provider value={value}>
      {currentAccessLevels && children}
    </AppContext.Provider>
  );
}

export function useApp() {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error("useApp must be used within a AppContext");
  }

  return context;
}
